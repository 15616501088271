<template>
  <div>
    <formText :data="{type: 'text', subtype: 'text', value: form[index].label, label: 'Überschrift'}" :callback="setLabel"/>
    <formTextarea :data="{type: 'text', subtype: 'text', value: form[index].description, label: 'Beschreibung'}" :callback="setDescription"/>
    <formRadio v-if="form[index].subtype !== 'email'" :data="{value: form[index].required, values: [{label: 'Pflichtfeld', value: true},{label: 'kein Pflichtfeld', value: false}]}" :callback="setRequired"/>
  </div>
</template>
<script>
export default {
  components: {
    formTextarea () { return import('@/components/formBuilder/parts/textarea') },
    formText () { return import('@/components/formBuilder/parts/text') },
    formRadio () { return import('@/components/formBuilder/parts/radio') }
  },
  props: {
    form: Array,
    index: Number
  },
  data () {
    return {

    }
  },
  methods: {
    setRequired (data) {
      this.form[this.index].required = data
    },
    setDescription (data) {
      this.form[this.index].description = data
    },
    setLabel (data) {
      this.form[this.index].label = data
    },
    setSubtype (data) {
      this.form[this.index].subtype = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
